<template>
  <FormulateForm
    ref="form"
    class="custom-fields-form"
    keep-model-data
    v-model="formValues"
    @submit="handleSubmit"
    autocomplete="false"
  >
    <h3>Custom Fields Setup</h3>

    <p><b>Step 1: Select your field type.</b></p>
    <FormulateInput
      type="select"
      name="type"
      placeholder="Please Select"
      :options="fieldTypes"
    />
    <template v-if="formValues.type">
      <div>
        <p>
          <b>
            Step 2: Input your options.
          </b>
        </p>
      </div>
      <div class="row custom-fields-form">
        <div class="col full">
          <FormulateInput
            type="text"
            name="question"
            label="Question"
            placeholder="How did you hear about the event?"
            validation="bail|required"
            autocomplete="false"
          />
          <FormulateInput
            type="checkbox"
            name="required"
            label="Require this option to be answered."
          />
          <FormulateInput
            type="checkbox"
            name="obv_tag"
            label="Use answer as Obvio Tag."
          />
        </div>
        <div class="col full">
          <!-- multiple choice questions -->
          <FormulateInput
            v-if="hasMultipleChoice"
            type="group"
            name="answers"
            repeatable
            label="Answers"
            add-label="+ Add Answer"
            validation="required|min:1"
          >
            <FormulateInput
              type="text"
              name="answer"
              placeholder="Answer"
              validation="required"
            />
          </FormulateInput>
        </div>
        <div class="col full">
          <FormulateInput
            type="checkbox"
            name="applicable_to"
            label="Applicable To"
            placeholder="Applicable To"
            validation="required"
            :options="applicableToTypes"
          />
        </div>
      </div>
      <div class="row">
        <div class="col full save-button">
          <FormulateInput
            type="submit"
            icon="plus"
            :loading="loading"
            :name="editingItem !== null ? 'Save Changes' : 'Save Custom Field'"
          />
        </div>
      </div>
    </template>
  </FormulateForm>
</template>

<script>
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTimes);

export default {
  name: "CustomFieldForm",
  components: {
    FontAwesomeIcon
  },
  props: {
    fieldTypes: Array,
    applicableToTypes: Array,
    editingItem: Object,
    loading: Boolean
  },
  data() {
    return {
      hideRepeatableGroups: false,
      formValues: {
        isNew: true,
        isChanged: false,
        type: "",
        required: false,
        question: "",
        answers: [{}],
        applicable_to: this.applicableToTypes.map(t => t.value),
        obv_tag: false
      }
    };
  },
  watch: {
    editingItem(value, oldValue) {
      if (value !== oldValue) {
        if (value !== null) {
          this.formValues = value;
        } else {
          this.clearForm();
          window.scroll({
            top: 1300,
            behavior: "smooth"
          });
        }
      }
    }
  },
  computed: {
    hasMultipleChoice() {
      return ["MULTIPLE_CHOICE", "MULTI_SELECT", "DROPDOWN"].includes(
        this.formValues.type
      );
    }
  },
  methods: {
    clearForm() {
      this.formValues = {
        isNew: true,
        isChanged: false,
        type: "",
        required: false,
        question: "",
        answers: [{}],
        applicable_to: this.applicableToTypes.map(t => t.value),
        obv_tag: false
      };

      this.$refs.form.hideErrors();
    },
    async handleSubmit() {
      const isEditing = this.editingItem !== null;

      if (!this.formValues.answers) {
        this.formValues.answers = [];
      } else {
        // filter out the empty object needed to show an input
        this.formValues.answers = this.formValues.answers.filter(a => a.answer);
      }

      this.$emit("save-field", this.formValues);
      this.clearForm();

      if (isEditing) {
        this.$toast("Custom Field updated!", { type: "success" });
      } else {
        this.$toast("Custom Field added!", { type: "success" });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.custom-fields-form {
  .variant {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding-right: 20px;
    margin-bottom: 20px;

    .input-wrapper {
      margin-bottom: 0;
    }

    .form-col {
      margin: 0.25em;
      width: 100%;

      &.name {
        width: calc(40% - 0.5em);
      }

      &.fee,
      &.price {
        width: calc(15% - 0.5em);
      }

      @media screen and (max-width: 800px) {
        &.name {
          width: calc(66.4% - 0.5em);
        }

        &.price {
          width: calc(33% - 0.5em);
        }

        &.fee {
          width: calc(33% - 0.5em);
        }
      }
    }
  }

  &::v-deep .remove-button {
    bottom: -4px;
  }

  .save-button {
    display: flex;
    width: 100%;
    margin-top: 20px;

    &::v-deep button {
      padding: 10px 0;
      width: 100%;
    }
  }

  .col {
    @media screen and (max-width: 800px) {
      &.quarter,
      &.half {
        width: 100% !important;
      }
    }
  }
}
</style>
