<template>
  <Accordion title="Example" v-model="active">
    <div class="row">
      <div class="col full">
        Your participation in answering a few quick questions would greatly help
        us make the event even better.
      </div>
    </div>
    <div class="row">
      <div class="col full">
        <FormulateInput
          type="text"
          label="Question"
          placeholder="John"
          max="40"
          ignored
        />
      </div>
    </div>
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="select"
          label="Question"
          validation="required"
          ignored
          placeholder="Select Option"
          :options="['Option 1', 'Option 2', 'Option 3']"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="multiSelect"
          label="Question"
          ignored
          placeholder="Select Options"
          :options="['Option 1', 'Option 2', 'Option 3']"
        />
      </div>
    </div>
    <div class="row">
      <div class="col full">
        <FormulateInput
          type="emoji"
          label="Question"
          ignored
          validation="required"
        />
      </div>
    </div>
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="radio"
          label="Question"
          validation="required"
          ignored
          :options="['Option 1', 'Option 2']"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="checkbox"
          label="Question"
          ignored
          :options="['Option 1', 'Option 2']"
        />
      </div>
    </div>
  </Accordion>
</template>

<script>
import Accordion from "@/components/Accordion.vue";

export default {
  name: "CustomFieldsExample",
  components: {
    Accordion
  },
  props: {
    value: Boolean
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
