<template>
  <div class="accordion" :class="{ active }">
    <div class="top" v-if="!hideArrows" @click="active = !active">
      <FontAwesomeIcon :icon="arrowIcon" />
      <span class="accordion-title">{{ title }}</span>
      <FontAwesomeIcon :icon="arrowIcon" />
    </div>
    <div
      class="top-no-arrows"
      v-if="hideArrows && !hidePointer"
      @click="active = !active"
    >
      <span class="accordion-title">{{ title }}</span>
    </div>
    <div
      class="top-no-arrows no-pointer"
      v-if="hideArrows && hidePointer"
      @click="active = !active"
    >
      <span class="accordion-title">{{ title }}</span>
    </div>
    <div class="bottom">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "Accordion",
  components: {
    FontAwesomeIcon
  },
  props: {
    value: Boolean,
    title: String,
    hideArrows: Boolean,
    hidePointer: Boolean,
    loggedIn: Boolean
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    arrowIcon() {
      return this.active ? faChevronUp : faChevronDown;
    }
  }
};
</script>

<style lang="less" scoped>
.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #30333a;
  border-radius: 8px;
  overflow: hidden;
  background: #1b1f23;
  margin-bottom: 16px;
  color: var(--text);

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 16px;
    gap: 32px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;

    .fa-chevron-down,
    .fa-chevron-up {
      max-height: 20px;
    }

    // span.accordion-title {
    //   color: var(--custom-event-eventTitles);
    // }
  }
  .top-no-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 16px;
    gap: 32px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;

    .fa-chevron-down,
    .fa-chevron-up {
      max-height: 20px;
    }

    // span.accordion-title {
    //   color: var(--custom-event-eventTitles);
    // }
  }
  .no-pointer {
    cursor: default;
  }

  .bottom {
    max-height: 0;
    display: none;
    background: var(--content-dark);
  }

  &.active {
    // overflow: visible;

    .bottom {
      display: initial;
      max-height: unset;
      padding: 16px;
    }
  }
}
</style>
